import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {isFeatureEnabled} from '@github-ui/feature-flags'
import {ThemeProvider} from '@primer/react-brand'

import {cohortFunnelBuilder} from '../../../lib/analytics'

import HeroSection from './_components/HeroSection'
import PricingSection from './_components/PricingSection'
import FeaturesSection from './_components/FeaturesSection'
import ResourcesSection from './_components/ResourcesSection'
import FootnotesSection from './_components/FootnotesSection'
import FaqSection from './_components/FaqSection'
import {ZodSilentErrorBoundary} from '../../../components/ZodSilentErrorBoundary/ZodSilentErrorBoundary'

export default function NewFeaturesCopilotIndex() {
  const {userHasOrgs} = useRoutePayload<{userHasOrgs: boolean}>()

  let {copilotProSignupPath} = useRoutePayload<{copilotProSignupPath: string}>()
  let {copilotForBusinessSignupPath} = useRoutePayload<{copilotForBusinessSignupPath: string}>()
  let {copilotEnterpriseSignupPath} = useRoutePayload<{copilotEnterpriseSignupPath: string}>()
  let {copilotBusinessContactSalesPath} = useRoutePayload<{copilotBusinessContactSalesPath: string}>()
  let {copilotEnterpriseContactSalesPath} = useRoutePayload<{copilotEnterpriseContactSalesPath: string}>()
  const {cft} = useRoutePayload<{cft: string}>()

  const withCft = cohortFunnelBuilder(cft)
  copilotProSignupPath = withCft(copilotProSignupPath, {product: 'cfi'})
  copilotForBusinessSignupPath = withCft(copilotForBusinessSignupPath, {product: 'cfb'})
  copilotEnterpriseSignupPath = withCft(copilotEnterpriseSignupPath, {product: 'ce'})
  copilotBusinessContactSalesPath = withCft(copilotBusinessContactSalesPath)
  copilotEnterpriseContactSalesPath = withCft(copilotEnterpriseContactSalesPath)
  const copilotPlansPath = withCft('/features/copilot/plans')

  return (
    <ThemeProvider colorMode="dark" className="lp-Copilot">
      <HeroSection copilotProSignupPath={copilotProSignupPath} copilotPlansPath={copilotPlansPath} />
      <FeaturesSection />
      <PricingSection
        copilotProSignupPath={copilotProSignupPath}
        copilotForBusinessSignupPath={copilotForBusinessSignupPath}
        copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
        copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
        copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
        userHasOrgs={userHasOrgs}
        isExpanded={!isFeatureEnabled('site_copilot_free_compare_toggle_enable')}
      />
      <ResourcesSection />
      <ZodSilentErrorBoundary>
        <FaqSection />
      </ZodSilentErrorBoundary>
      <FootnotesSection />
    </ThemeProvider>
  )
}

try{ NewFeaturesCopilotIndex.displayName ||= 'NewFeaturesCopilotIndex' } catch {}