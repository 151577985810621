export const SUBNAV_LINKS = {
  logo: {
    label: 'GitHub Copilot',
    url: '/features/copilot',
  },
  items: [
    {
      label: 'Overview',
      url: '/features/copilot',
    },
    {
      label: 'Plans',
      url: '/features/copilot/plans',
    },
    {
      label: 'Tutorials',
      url: '/features/copilot/tutorials',
    },
    {
      label: 'Extensions',
      url: '/features/copilot/extensions',
    },
    {
      label: 'What’s new',
      url: '/features/copilot/whats-new',
    },
  ],
}
