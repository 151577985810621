import {Box, Hero} from '@primer/react-brand'

import type {PrimerComponentHero} from '../../../schemas/contentful/contentTypes/primerComponentHero'
import {ContentfulAppStoreButtonGroup} from '../ContentfulAppStoreButtonGroup/ContentfulAppStoreButtonGroup'
import {getPrimerIcon} from '../../../lib/utils/icons'
import {getAnalyticsEvent} from '../../../lib/utils/analytics'
import styles from './ContentfulHero.module.css'

export type ContentfulHeroProps = {
  component: PrimerComponentHero

  'data-hpc'?: boolean
}

export function ContentfulHero({component, ...props}: ContentfulHeroProps) {
  const {
    align,
    callToActionPrimary,
    callToActionSecondary,
    description,
    descriptionVariant,
    heading,
    headingSize,
    image,
    imagePosition,
    label,
    labelColor,
    trailingComponent,
    videoSrc,
  } = component.fields

  const hasTrailingComponent = trailingComponent !== undefined && trailingComponent.length > 0

  const Octicon = typeof label !== 'string' && getPrimerIcon(label?.fields.icon)

  return (
    <Box className={styles.contentfulHeroContainer}>
      <Hero
        data-hpc={props['data-hpc']}
        align={align}
        trailingComponent={
          hasTrailingComponent
            ? () => (
                <ContentfulAppStoreButtonGroup
                  components={trailingComponent}
                  analyticsLabel="hero"
                  analyticsLocation="hero"
                />
              )
            : undefined
        }
        className="pb-0"
      >
        {label &&
          (typeof label === 'string' ? (
            <Hero.Label {...(labelColor ? {color: labelColor} : {})}>{label}</Hero.Label>
          ) : (
            <Hero.Label
              color={label.fields.color}
              size={label.fields.size}
              {...(Octicon ? {leadingVisual: <Octicon />} : {})}
            >
              {label.fields.text}
            </Hero.Label>
          ))}

        <Hero.Heading size={headingSize}>{heading}</Hero.Heading>

        {description && <Hero.Description variant={descriptionVariant}>{description}</Hero.Description>}

        {!hasTrailingComponent && callToActionPrimary !== undefined && (
          <Hero.PrimaryAction
            href={callToActionPrimary.fields.href}
            data-ref={`hero-primary-action-${callToActionPrimary.sys.id}`}
            {...getAnalyticsEvent(
              {
                action: callToActionPrimary.fields.text,
                tag: 'button',
                location: 'hero',
                context: 'CTAs',
              },
              {context: false},
            )}
          >
            {callToActionPrimary.fields.text}
          </Hero.PrimaryAction>
        )}

        {!hasTrailingComponent && callToActionSecondary !== undefined && (
          <Hero.SecondaryAction
            href={callToActionSecondary.fields.href}
            {...getAnalyticsEvent(
              {
                action: callToActionSecondary.fields.text,
                tag: 'button',
                location: 'hero',
                context: 'CTAs',
              },
              {context: false},
            )}
          >
            {callToActionSecondary.fields.text}
          </Hero.SecondaryAction>
        )}
        {image !== undefined && (
          <Hero.Image
            position={imagePosition === 'Inline' ? 'inline-end' : 'block-end'}
            src={image.fields.file.url}
            alt={image.fields.description || ''}
          />
        )}
      </Hero>
      {videoSrc && !image && (
        <Box className={styles.videoContainer}>
          {/* eslint-disable-next-line @eslint-react/dom/no-missing-iframe-sandbox */}
          <iframe
            role="application"
            className="border-0 width-full height-full position-absolute top-0 left-0"
            src={videoSrc}
            title={heading}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </Box>
      )}
    </Box>
  )
}

try{ ContentfulHero.displayName ||= 'ContentfulHero' } catch {}
try{ Octicon.displayName ||= 'Octicon' } catch {}