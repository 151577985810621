import {useRoutePayload} from '@github-ui/react-core/use-route-payload'
import {ThemeProvider} from '@primer/react-brand'

import CopilotSubNav from '../_components/CopilotSubNav'
import CallToAction from '../_components/CallToAction'
import PricingSection from '../_components/PricingSection'
import FootnotesSection from '../_components/FootnotesSection'
import FaqSection from '../_components/FaqSection'
import {cohortFunnelBuilder} from '../../../../lib/analytics'

import PricingBackground from '../_assets/pricing-bg.webp'
import PricingBackgroundSm from '../_assets/pricing-bg-sm.webp'

export default function NewFeaturesCopilotPlansIndex() {
  const {userHasOrgs} = useRoutePayload<{userHasOrgs: boolean}>()

  let {copilotProSignupPath} = useRoutePayload<{copilotProSignupPath: string}>()
  let {copilotForBusinessSignupPath} = useRoutePayload<{copilotForBusinessSignupPath: string}>()
  let {copilotEnterpriseSignupPath} = useRoutePayload<{copilotEnterpriseSignupPath: string}>()
  let {copilotBusinessContactSalesPath} = useRoutePayload<{copilotBusinessContactSalesPath: string}>()
  let {copilotEnterpriseContactSalesPath} = useRoutePayload<{copilotEnterpriseContactSalesPath: string}>()
  const {cft} = useRoutePayload<{cft: string}>()

  const withCft = cohortFunnelBuilder(cft)

  copilotProSignupPath = withCft(copilotProSignupPath, {product: 'cfi'})
  copilotForBusinessSignupPath = withCft(copilotForBusinessSignupPath, {product: 'cfb'})
  copilotEnterpriseSignupPath = withCft(copilotEnterpriseSignupPath, {product: 'ce'})
  copilotBusinessContactSalesPath = withCft(copilotBusinessContactSalesPath)
  copilotEnterpriseContactSalesPath = withCft(copilotEnterpriseContactSalesPath)

  return (
    <ThemeProvider colorMode="dark" className="lp-Copilot">
      <div className="position-relative">
        <CopilotSubNav currentUrl="/features/copilot/plans" />

        <picture>
          <source srcSet={PricingBackgroundSm} media="(max-width: 767px)" />
          <img src={PricingBackground} className="position-absolute top-0 left-0 width-full height-auto" alt="" />
        </picture>

        <PricingSection
          copilotProSignupPath={copilotProSignupPath}
          copilotForBusinessSignupPath={copilotForBusinessSignupPath}
          copilotEnterpriseSignupPath={copilotEnterpriseSignupPath}
          copilotBusinessContactSalesPath={copilotBusinessContactSalesPath}
          copilotEnterpriseContactSalesPath={copilotEnterpriseContactSalesPath}
          userHasOrgs={userHasOrgs}
          isExpanded
          showLabel={false}
          className="lp-Section--hero"
        />
      </div>

      <section className="lp-Section pt-5 pt-lg-8">
        <CallToAction copilotContactSalesPath={copilotBusinessContactSalesPath} />
      </section>

      <FaqSection />
      <FootnotesSection />
    </ThemeProvider>
  )
}

try{ NewFeaturesCopilotPlansIndex.displayName ||= 'NewFeaturesCopilotPlansIndex' } catch {}